.typewriter {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
  font-size: 1.5rem;
  line-height: 2rem;
  display: flex;
  flex-direction: column;

  animation: fade-in linear 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  font-size: 1.2rem;
  line-height: 3rem;
  margin-top: -20%;

  // iPhone 5, 5S, 5C and 5SE
  @media only screen and (min-width: 320px) and (orientation: portrait) {
    font-size: 1.2rem;
    line-height: 3rem;
    margin-top: -20%;
  }

  /* Landscape */
  @media only screen and (min-width: 320px) and (orientation: landscape) {
    font-size: 1rem;
    line-height: 2.5rem;
    margin-top: -10%;
  }

  // Samsung S4, 5, 6, and similar to Iphone 6/6s, 7, 8
  @media only screen and (min-width: 360px) and (orientation: portrait) {
    font-size: 1.3rem;
    line-height: 3.3rem;
    margin-top: -40%;
  }

  /* Landscape */
  @media only screen and (min-width: 360px) and (orientation: landscape) {
    font-size: 1rem;
    line-height: 2.5rem;
  }

  // Nexus 6p, 5x, all g-pixels and similar to Iphone 6+, 7+, 8+
  @media only screen and (min-width: 411px) and (orientation: portrait) {
    font-size: 1.4rem;
    line-height: 3.6rem;
    margin-top: -45%;
  }

  /* Landscape */
  @media only screen and (min-width: 411px) and (orientation: landscape) {
    font-size: 1.2rem;
    line-height: 3rem;
  }

  // (iPad Air 1, 2, Mini, 1, 2)
  @media only screen and (min-width: 768px) and (orientation: portrait) {
    font-size: 1.8rem;
    line-height: 4.8rem;
    margin-top: -20%;
  }

  /* Landscape */
  @media only screen and (min-width: 768px) and (orientation: landscape) {
    font-size: 1.3rem;
    line-height: 3.5rem;
    margin-top: -20%;
  }

  @media (min-width: 1280px) {
    font-size: 2rem;
    line-height: 5rem;
    margin-top: -15%;
  }

  @media (min-width: 1920px) {
    font-size: 2.5rem;
    line-height: 6rem;
  }
}

.typedText > h1 {
  margin: 0;
  position: absolute;
}

.static-p {
  margin: 0;
}
