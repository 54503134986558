.footer {
  grid-area: footer;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 0.7rem;

  letter-spacing: 0.05em;
  word-spacing: 0.05em;
  text-decoration: none;
  z-index: 1;
}
