.marker {
  display: inline-block;
  position: relative;
  z-index: 2;
  color: #42434d;
}
.marker-highlight::after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  animation: highlight 0.7s ease-in-out forwards;
  z-index: -2;
  transform: skewX(-8deg) rotate(-0.6deg);
  background: #fff2b3;
  border-radius: 3px;
  width: 0%;
}

@keyframes highlight {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
