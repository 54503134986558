.contact {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;

  letter-spacing: 0.02em;
  word-spacing: 0.05em;
  text-decoration: none;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-self: center;
  justify-content: space-evenly;
  height: 100%;

  animation: fade-in linear 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.contact-message {
  // Make the images not selectable, for a better user experience
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;

  line-height: 1.4em;

  @media (orientation: portrait) and (max-width: 899px) {
    font-size: calc(1.2vw + 1.2vh + 1.7vmin);
  }

  @media (orientation: landscape) and (max-width: 899px) {
    font-size: calc(1.1vw + 1.1vh + 1.5vmin);
  }

  @media (min-width: 900px) {
    padding: 0 18vw;
    font-size: 24px;
  }
}

.how-to-email {
  font-style: italic;

  @media (orientation: portrait) {
    font-size: calc(1.2vw + 1.2vh + 1vmin);
  }

  @media (orientation: landscape) {
    font-size: calc(1.1vw + 1.1vh + 1vmin);
  }

  @media (min-width: 900px) {
    font-size: 16px;
  }
}

.contact-email {
  font-size: 28px;

  @media (min-width: 900px) {
    font-size: 35px;
    padding: 0 18vw;
  }

  @media (max-height: 320px) {
    font-size: 22px;
  }
}

.contact-socials p {
  text-decoration: none;
  font-size: 24px;
  text-align: center;
  border-radius: 100%;
  color: #fff;
  box-shadow: inset 0 0 0 1px #d7d7d7;
  position: relative;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  margin: 0px;
}
.contact-socials p:hover,
.contact-socials p:focus {
  color: #fff;
  text-decoration: none;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.8);
  -webkit-box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.8);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  /*fallback*/
  background-image: linear-gradient(to bottom right, #fad0c4 20%, #ff9a9e 100%);
  // background-image: linear-gradient(-206deg, #ff9298 0%, #e4008e 100%);
}

.contact-socials .icon-codepen:hover,
.contact-socials .icon-codepen:focus {
  // background-image: linear-gradient(to bottom right, #e4efe9 20%, #93a5cf 100%);
}

.contact-social .icon-github-circled:hover,
.contact-social .icon-github-circled:focus {
  // background-image: linear-gradient(to bottom right, #d38af5 20%, #7c0bb1 100%);
}

.contact-socials .icon-linkedin:hover,
.contact-socials .icon-linkedin:focus {
  // background-image: linear-gradient(to bottom right, #c2e9fb 20%, #a1c4fd 100%);
}

.contact-socials {
  display: flex;
  flex-direction: row;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;

  @media (min-width: 900px) {
    padding: 0 18vw;
  }

  a {
    margin-right: 18px;
  }

  .logo {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;

    @media (min-width: 854px) {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 24px;
    }
  }
}

.paperplane-flying {
  pointer-events: none;
}

.tilt-pp1 {
  right: 0;
  bottom: 0;
  height: 60px;
  margin-right: 60px * 0.2;
  margin-bottom: 60px * 0.2;

  animation: pp1-fly-mobile ease 2s;
  animation-iteration-count: 1;
  // animation-fill-mode: forwards;

  .paperplane {
    -webkit-filter: drop-shadow(20px 20px 1.5px #464646);
    filter: drop-shadow(20px 20px 1.5px #464646);

    animation: pp1-fly-shadow-mobile ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  // iPhone 5, 5S, 5C and 5SE
  @media only screen and (min-width: 320px) and (orientation: portrait) {
    margin-right: 60px * 0.5;
    margin-bottom: 60px * 0.6;
  }
  /* Landscape */
  @media only screen and (min-width: 320px) and (orientation: landscape) {
    margin-right: 60px * 1;
    margin-bottom: 60px * 0.6;

    animation: pp1-fly-mobile-landscape ease 3s;
    animation-iteration-count: 1;
    // animation-fill-mode: forwards;

    .paperplane {
      animation: pp1-fly-shadow-mobile-landscape ease 3s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  // Samsung S4, 5, 6, and similar to Iphone 6/6s, 7, 8
  @media only screen and (min-width: 360px) and (orientation: portrait) {
    margin-right: 60px * 0.35;
    margin-bottom: 60px * 0.35;
  }
  /* Landscape */
  @media only screen and (min-width: 360px) and (orientation: landscape) {
    margin-right: 60px * 1.4;
    margin-bottom: 60px * 0.8;

    animation: pp1-fly-mobile-landscape ease 3s;
    animation-iteration-count: 1;
    // animation-fill-mode: forwards;

    .paperplane {
      animation: pp1-fly-shadow-mobile-landscape ease 3s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  // Nexus 6p, 5x, all g-pixels and similar to Iphone 6+, 7+, 8+
  @media only screen and (min-width: 411px) and (orientation: portrait) {
    height: 80px;
    margin-right: 80px * 0.45;
    margin-bottom: 80px * 0.45;
  }
  /* Landscape */
  @media only screen and (min-width: 411px) and (orientation: landscape) {
    height: 80px;
    margin-right: 80px * 1.2;
    margin-bottom: 80px * 0.5;

    animation: pp1-fly-mobile-landscape ease 3s;
    animation-iteration-count: 1;
    // animation-fill-mode: forwards;

    .paperplane {
      animation: pp1-fly-shadow-mobile-landscape ease 3s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  // (Ipad Air 1, 2, Mini, 1, 2)
  @media only screen and (min-width: 768px) and (orientation: portrait) {
    height: 120px;
    margin-right: 120px * 0.7;
    margin-bottom: 120px * 0.8;
  }
  /* Landscape */
  @media only screen and (min-width: 768px) and (orientation: landscape) {
    height: 100px;
    margin-right: 100px * 1.5;
    margin-bottom: 100px * 0.8;

    animation: pp1-fly-mobile-landscape ease 3s;
    animation-iteration-count: 1;
    // animation-fill-mode: forwards;

    .paperplane {
      animation: pp1-fly-shadow-mobile-landscape ease 3s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  @media (min-width: 1280px) {
    animation: pp1-fly ease 2s;
    animation-iteration-count: 1;
    // animation-fill-mode: forwards;

    .paperplane {
      -webkit-filter: drop-shadow(20px 20px 1.5px #464646);
      filter: drop-shadow(20px 20px 1.5px #464646);

      animation: pp1-fly-shadow ease 2s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  @media (min-width: 1920px) {
    height: 130px;
  }
}
.tilt-pp2 {
  left: 0;
  top: 0;
  height: 30px;
  visibility: hidden;
  position: absolute;

  animation: pp2-fly-mobile ease 2s;
  animation-iteration-count: 1;
  // animation-fill-mode: forwards;

  .paperplane {
    -webkit-filter: drop-shadow(10px 10px 1.5px #464646);
    filter: drop-shadow(10px 10px 1.5px #464646);

    animation: pp2-fly-shadow-mobile ease 2;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  // iPhone 5, 5S, 5C and 5SE
  @media only screen and (min-width: 320px) and (orientation: portrait) {
    visibility: visible;
    height: 40px;
    margin-left: 40px * 0.5;
    margin-top: 40px * 0.5;
  }

  // Nexus 6p, 5x, all g-pixels and similar to Iphone 6+, 7+, 8+
  @media only screen and (min-width: 411px) and (orientation: portrait) {
    height: 55px;
    margin-left: 55px * 0.5;
    margin-top: 55px * 0.5;
  }

  // (Ipad Air 1, 2, Mini, 1, 2)
  @media only screen and (min-width: 768px) and (orientation: portrait) {
    height: 80px;
    margin-left: 80px * 0.5;
    margin-top: 80px * 0.5;
  }
  /* Landscape */
  @media only screen and (min-width: 768px) and (min-height: 768px) and (orientation: landscape) {
    height: 70px;
    visibility: visible;
    margin-left: 70px * 1.5;
    margin-top: 70px * 1.9;
  }

  @media (min-width: 1280px) {
    height: 90px;
    visibility: visible;
    margin-left: 90px * 1.7;
    margin-top: 90px * 4;

    animation: pp2-fly ease 2s;
    animation-iteration-count: 1;
    // animation-fill-mode: forwards;

    .paperplane {
      -webkit-filter: drop-shadow(20px 20px 1.5px #464646);
      filter: drop-shadow(20px 20px 1.5px #464646);

      animation: pp2-fly-shadow ease 2;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  @media (min-width: 1920px) {
    height: 110px;
  }
}
.tilt-pp3 {
  right: 10%;
  height: 45px;
  top: 0;
  margin-right: 140px;
  margin-top: 40px * 3;
  visibility: hidden;

  animation: pp3-fly ease 3s;
  animation-iteration-count: 1;
  // animation-fill-mode: forwards;

  .paperplane {
    -webkit-filter: drop-shadow(9px 9px 1.4px #464646);
    filter: drop-shadow(9px 9px 1.4px #464646);

    animation: pp3-fly-shadow ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  @media (min-width: 1280px) {
    visibility: visible;
  }

  @media (min-width: 1920px) {
    height: 80px;
    margin-right: 80px * 1.4;
    margin-bottom: 80px * 3;
  }
}
.tilt-pp4 {
  right: 40%;
  height: 80px;
  margin-bottom: 80px * 0.8;
  visibility: hidden;

  animation: pp4-fly ease 3s;
  animation-iteration-count: 1;
  // animation-fill-mode: forwards;

  .paperplane {
    -webkit-filter: drop-shadow(12px 12px 1.4px #464646);
    filter: drop-shadow(12px 12px 1.4px #464646);

    animation: pp4-fly-shadow ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  @media (min-width: 1280px) {
    visibility: visible;
  }

  @media (min-width: 1920px) {
    height: 100px;
    margin-bottom: 75px * 0.8;
  }
}

.tilt {
  position: absolute !important;
  transform-style: preserve-3d;
  transform: perspective(1000px);
  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-filter: contrast(1.5);
  filter: contrast(1.5);
}

.paperplane {
  z-index: 2;
  height: 100%;
  transform-style: preserve-3d;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;

  -webkit-filter: contrast(1.5);
  filter: contrast(1.5);
}

@keyframes pp1-fly {
  0% {
    transform: translate3d(40vw, 40vh, 1000px);
    height: 250px;
  }
  100% {
  }
}

@keyframes pp1-fly-shadow {
  0% {
    -webkit-filter: drop-shadow(200px 200px 1.4px #464646);
    filter: drop-shadow(200px 200px 1.4px #464646);
  }
  100% {
  }
}

@keyframes pp2-fly {
  0% {
    transform: translate3d(-40vw, 25vh, 1000px);
    height: 200px;
  }
  100% {
  }
}

@keyframes pp2-fly-shadow {
  0% {
    -webkit-filter: drop-shadow(200px 200px 1.4px #464646);
    filter: drop-shadow(200px 200px 1.4px #464646);
  }
  100% {
  }
}

@keyframes pp1-fly-mobile {
  0% {
    transform: translate3d(100vw, 50vh, 1000px);
    height: 250px;
  }
  100% {
  }
}

@keyframes pp1-fly-shadow-mobile {
  0% {
    -webkit-filter: drop-shadow(100px 100px 1.4px #464646);
    filter: drop-shadow(100px 100px 1.4px #464646);
  }
  100% {
  }
}

@keyframes pp1-fly-mobile-landscape {
  0% {
    transform: translate3d(100vw, 100vh, 1000px);
    height: 250px;
  }
  100% {
  }
}

@keyframes pp1-fly-shadow-mobile-landscape {
  0% {
    -webkit-filter: drop-shadow(100px 100px 1.4px #464646);
    filter: drop-shadow(100px 100px 1.4px #464646);
  }
  100% {
  }
}

@keyframes pp2-fly-mobile {
  0% {
    transform: translate3d(-80vw, 25vh, 1000px);
    height: 200px;
  }
  100% {
  }
}

@keyframes pp2-fly-shadow-mobile {
  0% {
    -webkit-filter: drop-shadow(200px 200px 1.4px #464646);
    filter: drop-shadow(200px 200px 1.4px #464646);
  }
  100% {
  }
}

@keyframes pp3-fly {
  0% {
    transform: translate3d(-100vw, -1vh, 1000px);
    height: 150px;
  }
  100% {
  }
}

@keyframes pp3-fly-shadow {
  0% {
    -webkit-filter: drop-shadow(150px 150px 1.4px #464646);
    filter: drop-shadow(150px 150px 1.4px #464646);
  }
  100% {
  }
}

@keyframes pp4-fly {
  0% {
    transform: translate3d(60vw, 5vh, 1000px);
    height: 200px;
  }
  100% {
  }
}

@keyframes pp4-fly-shadow {
  0% {
    -webkit-filter: drop-shadow(150px 150px 1.4px #464646);
    filter: drop-shadow(150px 150px 1.4px #464646);
  }
  100% {
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
