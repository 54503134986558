.container-grid {
  display: grid;
  width: 100%;
  height: 100%;

  grid-template-columns: 5% 1fr 5%;
  grid-template-rows: 10% 1fr 1rem;

  // When on Desktop, make the nav-grid-area a bit bigger so pyramid doesn't touch top
  @media (min-width: 1200px) {
    grid-template-columns: 5% 1fr 5%;
    grid-template-rows: 15% 1fr 1rem;
  }

  grid-template-areas:
    ". nav ."
    ". main-content ."
    ". footer .";
}
