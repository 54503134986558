.menu {
  grid-column: 1 / 4;
  grid-row: 1 / 4;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  z-index: 10;
  visibility: hidden;

  background-color: none;
  // How much it takes when closing (NOT OPENING)
  transition: background-color 1s ease-in, visibility 1s ease-in;
  transition-delay: 0.2s;

  & > .menu-list {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    // This is how fast when turning normal (collapsed)
    $cubic: cubic-bezier(0.4, 0.01, 0.165, 0.99);
    @for $i from 1 through 5 {
      & > .menu-button:nth-child(#{$i}) {
        transform: scale(1.15) translateY(-4vh) translateX(8vw);
        opacity: 0;
        transition: transform 0.5s $cubic, opacity 0.3s $cubic;
        transition-delay: #{0.56 - ($i * 0.07)}s;
      }
    }

    // This is when it's closed
    & > .menu-button {
      -webkit-user-select: none; /* Chrome all / Safari all */
      -moz-user-select: none; /* Firefox all */
      -ms-user-select: none; /* IE 10+ */
      user-select: none;

      font-family: arial;
      color: #ffffff;
      letter-spacing: 0.5em;
      text-transform: uppercase;
      font-size: 24px;
      text-decoration: none;

      pointer-events: none;
      cursor: default;
    }
  }

  @media (min-width: 1200px) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    justify-content: center;
    z-index: 1;

    & > .menu-list {
      background: dark-gray;
      width: 90%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      // This is how fast when turning normal (collapsed)
      /* FOR DESKTOP, YOU SHOULD REMOVE THE CUBIC */
      $cubic: cubic-bezier(0.4, 0.01, 0.165, 0.99);
      @for $i from 1 through 5 {
        & > .menu-button:nth-child(#{$i}) {
          transform: translateX(-12vw);
          opacity: 0;
          transition: transform 0.5s $cubic, opacity 0.3s $cubic;
          transition-delay: #{0.56 - ($i * 0.07)}s;
        }
      }

      // This is when it's closed
      & > .menu-button {
        font-family: arial;
        color: #ffffff;
        letter-spacing: 0.5em;
        text-transform: uppercase;
        font-size: 18px;
        text-decoration: none;

        pointer-events: none;
        cursor: default;
      }
    }
  }
}
.menu.opened {
  background-color: #000000;
  transition: background-color 0.5s ease-out;
  transition-delay: 0.2s;
  z-index: 10;
  visibility: visible;

  grid-column: 1 / 4;
  grid-row: 1 / 4;

  & > .menu-list.opened {
    & > .menu-button {
      pointer-events: visible;
      cursor: pointer;
    }

    // Opened
    @for $i from 1 through 5 {
      & > .menu-button:nth-child(#{$i}) {
        transform: scale(1) translateY(0px) translateX(0px);
        opacity: 1;
        transition-delay: #{0.07 * $i + 0.2}s;
      }
    }
  }

  @media (min-width: 1200px) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;

    justify-content: center;
    background-color: rgba(#ffffff, 0);

    & > .menu-list {
      width: 90%;

      & > .menu-button {
        pointer-events: visible;
        cursor: pointer;
        z-index: 10;
      }

      // Opened
      @for $i from 1 through 5 {
        & > .menu-button:nth-child(#{$i}) {
          opacity: 1;
          transition-delay: #{0.07 * $i + 0.2}s;
        }
      }
    }
  }
}
