@import "./1-base/fonts";
@import "./2-components/typewriter", "./2-components/pyramid",
  "./2-components/menu", "./2-components/particlesX", "./2-components/lava-lamp";
@import "./3-layout/footer", "./3-layout/grid", "./3-layout/main-content",
  "./3-layout/navigation", "./2-components/marker";
@import "./4-pages/contact", "./4-pages/about";

:root {
  font-size: 16px;
}

#root {
  width: 100%;
  height: 100%;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background: linear-gradient(-206deg, #835eff 0%, #ff008d 100%);
  // background: linear-gradient(to top, #ff5858, #f857a6);
  color: white;

  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden !important;
}
