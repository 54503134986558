@import "node_modules/mathsass/dist/math";
@import "bourbon";

// Less than 52px will look a bit opend in some sides
$pyramid-height: 40px;
$pyramid-width: 40px;
$catet-min: $pyramid-width/2;

$ph: strip-unit($pyramid-height);
$pw: strip-unit($pyramid-width);
$ctm: strip-unit($catet-min);

$slant-no: sqrt((pow($ph, 2))+ (pow(($ctm), 2)));
$slant-angle: rad-to-deg(asin($ph/$slant-no));
$slant: $slant-no * 1px;
$op-angle: 180 - $slant-angle;

$pyramid-opacity: 0.8;

.pyramid {
  z-index: 100;
  position: relative;
  top: 18px;
  left: -2px;
  cursor: pointer;
  background: none;
  border: none;

  // If it's a phone (max-width: 740px) like S8, and is in landscape mode, then move a bit the pyramid
  @media (orientation: landscape) and (max-width: 740px) {
    top: 28px;
    left: 2px;
  }

  @media (min-width: 1200px) {
    transform: scale(1.5);
  }
}

.pyramid-base {
  width: $pyramid-width;
  height: $pyramid-width;
  transform-style: preserve-3d;
  transform: rotateX(70deg) rotate(22deg);
  animation: rotation 4s linear infinite;
  background: rgba(#4f4f4f, 0.5);

  &:hover {
    animation: none;
  }

  @media (min-width: 1200px) {
    animation: rotation 4s linear infinite;

    &:hover {
      animation: rotation-hover 0.35s linear 1;
    }
  }

  &:hover > .side-1 {
    opacity: $pyramid-opacity;
    &:before {
      border-color: #373737 transparent transparent;
    }
  }
  &:hover > .side-2 {
    opacity: $pyramid-opacity;
    &:before {
      border-color: #373737 transparent transparent;
    }
    @media (min-width: 1200px) {
      &:before {
        border-color: transparent transparent transparent;
      }
    }
  }
  &:hover > .side-3 {
    opacity: $pyramid-opacity;
    &:before {
      border-color: #373737 transparent transparent;
    }
  }
  &:hover > .side-4 {
    opacity: $pyramid-opacity;
    &:before {
      border-color: #373737 transparent transparent;
    }
  }
  &:hover > .shadow {
    opacity: $pyramid-opacity;
    &:before {
      border-color: #1d1d1d #1d1d1d #1d1d1d #1d1d1d;
    }
  }
}

.pyramid-base.active {
  animation: none;

  &:hover {
    animation: none;
  }

  .side-1,
  .side-2,
  .side-3,
  .side-4 {
    opacity: $pyramid-opacity;
    &:before {
      border-color: transparent transparent transparent transparent;
    }
  }

  @media (min-width: 1200px) {
    animation: none;

    .side-2 {
      opacity: $pyramid-opacity;
      transform: translateX($pyramid-width) rotateZ(90deg) rotateX($slant-angle);
      &:before {
        border-color: transparent transparent transparent transparent;
      }
    }
    .side-1,
    .side-3,
    .side-4 {
      opacity: $pyramid-opacity;
      &:before {
        border-color: #373737 transparent transparent;
      }
    }
  }
}

.triangle {
  transform-style: preserve-3d;
  width: 0;
  height: 0;
}

.triangle:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: $slant $catet-min 0 $catet-min;
}

.side-1 {
  opacity: $pyramid-opacity;
  transform: translatex(0) rotatey($op-angle) rotatez(90deg);
  &:before {
    border-color: #373737 transparent transparent transparent;
  }
}
.side-2 {
  opacity: $pyramid-opacity;
  transform: translateX($pyramid-width) rotatez(90deg) rotatex($slant-angle);
  &:before {
    border-color: #373737 transparent transparent transparent;
  }
}
.side-3 {
  opacity: $pyramid-opacity;
  transform: translatez(0) rotatex($slant-angle);
  &:before {
    border-color: #373737 transparent transparent transparent;
  }
}
.side-4 {
  opacity: $pyramid-opacity;
  transform: translatey($pyramid-width) rotatex($op-angle);
  &:before {
    border-color: #373737 transparent transparent transparent;
  }
}

.shadow {
  opacity: $pyramid-opacity;
  $shadow-bw: $catet-min * 1.08;
  $shadow-margin: ($pyramid-width - ($shadow-bw * 2))/2;
  transform: translateZ(-($pyramid-width * 0.27)) translateX($shadow-margin)
    translateY($shadow-margin);
  &:before {
    border-color: #1d1d1d #1d1d1d #1d1d1d #1d1d1d;
    background-color: #1d1d1d;
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }

  &:before {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: $shadow-bw $shadow-bw $shadow-bw $shadow-bw;
  }
}

@keyframes rotation {
  100% {
    transform: rotateX(70deg) rotate(382deg);
  }
}

@keyframes rotation-hover {
  100% {
    transform: rotateX(70deg) rotate(382deg);
  }
}

@keyframes rotation-flat {
  100% {
    transform: rotateX(70deg) rotate(292deg);
  }
}

.pyramid-container {
  //background: #1c8e8e;
  grid-area: nav;
  justify-self: flex-end;
  align-self: center;
  display: flex;

  @media (min-width: 1200px) {
    justify-self: flex-start;
  }

  @media screen and (orientation: landscape) and (max-width: 1199px) {
    margin-top: 5vh;
  }
}
