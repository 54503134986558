.about {
  grid-area: main-content;
  display: flex;
  align-items: center;
}

.about-container {
  display: flex;
  align-items: center;
  justify-content: center;

  animation: fade-in linear 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.about-message {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;

  @media (orientation: portrait) {
    font-size: calc(1.2vw + 1.2vh + 1.5vmin);
  }

  @media (orientation: landscape) {
    font-size: calc(1.1vw + 1.1vh + 1vmin);
  }

  letter-spacing: 0.02em;
  word-spacing: 0.05em;
  text-decoration: none;
  line-height: 1.4em;

  @media (min-width: 700px) and (min-height: 700px) {
    font-size: 24px;
  }

  @media (min-width: 1024px) and (min-height: 700px) {
    padding-left: 17vw;
    padding-right: 17vw;
    font-size: 24px;
  }
}

#popConfetti {
}

.party-time {
  color: #ffffff;
  text-decoration: none;
}

.party-time:hover {
  color: #fff2b3;
}

.confetti-emoji {
  height: 1em;
  transform: translateY(18%);
}
